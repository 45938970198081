import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";

const Title = styled.h1`
    ${typography.h2};
    @media screen and (min-width: 1800px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 754px;
    }
`;

const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
    p{
        font-weight: normal;

        @media screen and (min-width: 768px) {
            max-width: 476px;
        }

        @media screen and (min-width: 1200px) {
            max-width: 785px;
        }
    }
`;

const HeroSimple = ({title, tagline, theme}) => {
    return (
        <Section paddingS="133px 0 40px" paddingM="203px 0 48px" paddingL="238px 0 96px" paddingXL="258px 0 56px" paddingXXL="278px 0 76px" bg="white" fg="dark" theme={theme} flexGrow="1">
            <Container>
                <Title>
                    {title}
                </Title>
                <Standard dangerouslySetInnerHTML={{ __html: tagline }}/>
            </Container>
        </Section>
    );
};

export default HeroSimple;
