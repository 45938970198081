import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroSimple from "../components/General/HeroSimple";
import IntroLegal from "../components/Legal/IntroLegal";
import Body from "../components/Legal/Body";
import Footer from "../components/Context/Footer";
import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const Legal = ({ data: staticData}) => {
    const theme = "dark";
    const data = usePreviewData(staticData);
    const {name, meta_description, share_image, tagline, intro, content} = data.prismicLegal.data;
    return (
        <Layout>
                <SEO title={name.text} description={meta_description} image={share_image.url} />
                <Header theme={theme} />
                <HeroSimple theme={theme} title={name.text} tagline={tagline.html} />
                <IntroLegal theme={theme} intro={intro.html} />
                <Body theme={theme} content={content.html} />
                <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query Legal($slug: String!) {
        prismicLegal(uid: {eq: $slug}) {
            data {
                content {
                    html
                }
                intro {
                    html
                }
                name {
                    text
                }
                tagline {
                    html
                }
                meta_description
                share_image {
                    url
                }
            }
            uid
        }
    }
`



export default Legal;
