import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";

const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
    color: ${color("dark")};

    strong{
        text-transform: uppercase;
        font-weight: 600;
        font-family: Faktum, sans-serif;
        line-height: 1.2;
        font-size: 16px;
        display: block;

        @media screen and (min-width: 768px) {
            font-size: 18px;
            line-height: 1.1;
            display: inline;
        }
    }

    ul{
        li{
            margin-bottom: 20px;

            @media screen and (min-width: 768px) {
                margin-bottom: 0;
            }
        }
    }
`;

const Intro = ({intro, theme}) => {
    return (
        <Section paddingS="22px 0" paddingM="28px 0" paddingL="44px 0" paddingXL="64px 0" paddingXXL="84px 0" bg="lite blue" fg="dark" theme={theme}>
            <Container>
                <Standard dangerouslySetInnerHTML={{ __html: intro }}/>
            </Container>
        </Section>
    );
};

export default Intro;
