import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";

const Standard = styled.div`
    ${typography.standard};
    color: ${color("dark")};

    h2{
        ${typography.p1};
        margin-bottom: 24px;
        font-weight: bold;
    }

    h3{
        ${typography.label};
        margin-bottom: 26px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 64px;
        font-size: 18px;

        @media screen and (min-width: 1200px) {
            font-size: 20px;
        }
    }

    h4, h5, h6{
        ${typography.label};
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 0;
    }

    p, ul, ol, h1, h2, h3, h4, h5, h6{
        @media screen and (min-width: 768px) {
            margin-left: 57px;
            margin-right: 57px;
        }

        @media screen and (min-width: 1200px) {
            width: 719px;
            margin-left: 0;
            margin-right: 0;
        }

        @media screen and (min-width: 1800px) {
            width: 754px;
        }
    }

    h1 + h2, h1 + h3, h1 + h4, h1 + h5, h1 + h6, h2 + h3, h2 + h4, h2 + h5, h2 + h6, h3 + h4, h3 + h5, h3 + h6, h4 + h5, h4 + h6, h5 + h6{
        margin-top: 0;
    }

    figure, .embed-container{
        margin-top: 25px;
        margin-bottom: 25px;

        figcaption{
            ${typography.body2};
            margin-top: 8px;
            text-align: left;
        }

        @media screen and (min-width: 768px) {
            margin-top: 48px;
            margin-bottom: 48px;
        }

        @media screen and (min-width: 1200px) {
            width: auto;
            max-width: 839px;
            text-align: center;
            margin: 64px auto;
        }

        @media screen and (min-width: 1800px) {
            width: auto;
            max-width: 1217px;
            margin-top: 72px;
            margin-bottom: 72px;

            figcaption{
                margin-top: 16px;
            }
        }
    }

    a{
        word-wrap: break-word;
    }
`;

const Body = ({content, theme}) => {
    return (
        <Section bg="white" fg="dark" theme={theme}>
            <Container>
                <Standard dangerouslySetInnerHTML={{ __html: content }}/>
            </Container>
        </Section>
    );
};

export default Body;
